<template>
  <BaaSManagement />
</template>

<script>
import { BaaSManagement } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSManagement,
  },
};
</script>
